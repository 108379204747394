import { useState, useEffect } from 'react';
import { IconArrowLeft, IconSrSearch, IconX } from '../icons';
import { Badge, Button, Checkbox, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input } from './';
import { FilterSchemaProperties, SchemaDate } from '../types';
import { SharedSelection } from '@nextui-org/react';
import { useDebounce } from '../hooks';

interface EnumDropdownProps {
  subFilter: string
  lastCreated: string
  setLastCreated: (value: string) => void
  removeFilter: (value: string) => void
  currentSubFilter: FilterSchemaProperties
  setKeys: Set<string | SchemaDate> | undefined
  onSelectValue: (keys: SharedSelection, filter: string, multiple: boolean) => void
}

const EnumDropdown = ({
  subFilter,
  lastCreated,
  setLastCreated,
  removeFilter,
  currentSubFilter,
  setKeys,
  onSelectValue,
}: EnumDropdownProps) => {
  const [autocompleteSearch, setAutocompleteSearch] = useState('');
  const [value, setValue] = useState<any>(setKeys ? new Set(Array.from(setKeys).filter((key): key is string => typeof key === 'string')) : new Set());
  const debouncedValue = useDebounce(value, currentSubFilter?.multiple ? 1500 : 100);

  useEffect(() => {
    if (JSON.stringify(value) === JSON.stringify(setKeys)) return;
    onSelectValue(debouncedValue, subFilter, !!currentSubFilter?.multiple);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const labelsSelected = typeof currentSubFilter.value === 'string'
    ? [currentSubFilter.enum?.find((enumItem) => enumItem.value === currentSubFilter.value)?.label]
    : Array.isArray(currentSubFilter.value)
      ? currentSubFilter.value.map((item) => currentSubFilter.enum?.find((enumItem) => enumItem.value === item)?.label)
      : [];

  if (!currentSubFilter.enum) return null;

  return (
    <Dropdown
      key={subFilter}
      radius='sm'
      className=''
      classNames={{
        content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0',
      }}
      placement='bottom-start'
      isOpen={lastCreated === subFilter}
      onOpenChange={(x) => setLastCreated(x ? subFilter : '')}
    >
      <Badge
        content={<IconX height={10} width={10} />}
        color='danger'
        className='h-5 w-5 right-1 cursor-pointer before:rounded-full before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full border-none before:bg-error/40 bg-complementary-gray-main text-[#E11900]'
        onClick={() => removeFilter(subFilter)}
      >
        <DropdownTrigger>
          <Button
            color='GrayMain'
            variant='bordered'
            endContent={<IconArrowLeft className='-rotate-90' width={20} height={20} />}
          >
            {subFilter}
            {currentSubFilter.value && currentSubFilter.value !== '' && ': '}
            {
              (labelsSelected?.join(', ') ?? '').slice(0, 10) + ((labelsSelected?.join(', ')?.length ?? 0) > 10 ? ' ...' : '')
            }
          </Button>
        </DropdownTrigger>
      </Badge>
      <DropdownMenu
        aria-label='Custom item styles'
        className='p-3 w-full max-w-full max-h-[50dvh] overflow-y-auto'
        selectionMode={currentSubFilter.multiple ? 'multiple' : 'single'}
        closeOnSelect={!currentSubFilter.multiple}
        disallowEmptySelection
        onSelectionChange={(k) => setValue(k)}
        selectedKeys={value ? new Set(Array.from(value).filter((key): key is string => typeof key === 'string')) : new Set()}
        itemClasses={{
          wrapper: ['p-0'],
          description: ['p-0'],
          base: [
            'rounded-md flex flex-row-reverse gap-5',
            'data-[hover=true]:bg-[#000B2533]',
            'data-[selectable=true]:focus:bg-[#000B2533]',
            'data-[selected=true]:bg-complementary-gray-dark'
          ],
        }}
        topContent={
          <>
            {
              currentSubFilter.selectAll && (
                <Button
                  className='overflow-visible min-h-8'
                  color='GradientAlfredBlue'
                  onPress={() => {
                    setValue(new Set(currentSubFilter.enum?.map(
                      (item) => item.value) ?? []));
                  }}
                >
                  Seleccionar Todos
                </Button>
              )
            }
            {
              currentSubFilter.type === 'autocomplete' && (
                <Input
                  className='w-full'
                  color='alfred'
                  placeholder='Buscar'
                  startContent={
                    <IconSrSearch />
                  }
                  onChange={(e) => {
                    setAutocompleteSearch(e.target.value);
                  }}
                  value={autocompleteSearch}
                  defaultValue={autocompleteSearch}
                />)
            }
          </>

        }
      >
        {
          currentSubFilter.enum
            .filter((item) => item.label.toLowerCase().includes(autocompleteSearch?.toLowerCase()))
            .map((item, i) => (
              <DropdownItem
                textValue={item.label}
                key={item.value}
                className='flex items-start rounded-[5px] mb-1 py-3 px-4 text-white font-medium'
                title={item.label}
                hideSelectedIcon={!currentSubFilter.multiple}
                classNames={{

                }}
                selectedIcon={
                  <Checkbox
                    color='secondary'
                    isSelected={value?.has(item.value)}
                    classNames={{ wrapper: 'before:border-1 before:border-complementary-gray-dark' }}
                  />
                }
              />
            ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

export default EnumDropdown;
