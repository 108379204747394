import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

export const useQueryString = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const currentQuery = new URLSearchParams(searchParams.toString()).toString();

  const removeQueryString = useCallback(
    (name: string | string[], scroll?: boolean) => {
      const params = new URLSearchParams(searchParams.toString());
      if (Array.isArray(name)) {
        name.forEach((n) => params.delete(n));
      } else {
        params.delete(name);
      }
      router.push(`${pathname}?${params.toString()}`, { scroll: scroll ?? false });
    },
    [searchParams, pathname, router]
  );

  const updateQueryString = useCallback(
    (name: string, value: string, scroll?: boolean) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);
      router.push(`${pathname}?${params.toString()}`, { scroll: scroll ?? false });
    },
    [pathname, router, searchParams]
  );

  const updateMultipleQueryString = useCallback(
    (values: Array<{ name: string, value: string }>, scroll?: boolean) => {
      const params = new URLSearchParams(searchParams.toString());
      values.forEach((v) => params.set(v.name, v.value));
      router.push(`${pathname}?${params.toString()}`, { scroll: scroll ?? false });
    },
    [pathname, router, searchParams]
  );

  const updateAndRemoveQueryString = useCallback(
    (name: string, value: string, remove: string | string[], scroll?: boolean) => {
      const params = new URLSearchParams(searchParams.toString());
      if (Array.isArray(remove)) {
        remove.forEach((n) => params.delete(n));
      } else {
        params.delete(remove);
      }
      params.set(name, value);
      router.push(`${pathname}?${params.toString()}`, { scroll: scroll ?? false });
    },
    [pathname, router, searchParams]
  );

  return {
    pathname,
    router,
    currentQuery,
    removeQueryString,
    updateQueryString,
    searchParams,
    updateAndRemoveQueryString,
    updateMultipleQueryString,
  };
};

export default useQueryString;
