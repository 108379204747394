import { QRCode } from 'react-qrcode-logo';
import { Button } from './Button';
import { MutableRefObject, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface GenerateQrProps {
  text: string
  size?: number
  bgColor?: string
  color?: string
  downloadPosition?: 'top' | 'bottom' | 'left' | 'right'
  hideQR?: boolean
  qrStyle?: 'dots' | 'squares' | 'fluid'
};
export const GenerateQr = ({ text, size, bgColor, color, downloadPosition, hideQR, qrStyle }: GenerateQrProps) => {
  const ref = useRef<QRCode>();
  return (
    <div
      className={
        twMerge(
          'flex gap-3 justify-center items-center',
          downloadPosition === 'top' && 'flex-col-reverse',
          downloadPosition === 'bottom' && 'flex-col',
          downloadPosition === 'left' && 'flex-row-reverse',
          downloadPosition === 'right' && 'flex-row'
        )
      }
    >

      <QRCode
        ref={ref as MutableRefObject<QRCode>}
        value={text}
        eyeRadius={13}
        size={size}
        qrStyle={qrStyle ?? 'dots'}
        ecLevel='L'
        quietZone={0}
        bgColor={bgColor ?? 'transparent'}
        fgColor={color ?? 'white'}
        style={{ display: hideQR ? 'none' : 'block' }}
      />
      {
        downloadPosition && (
          <Button
            color='DarkMain'
            onPress={() => {
              ref.current?.download();
            }}
          >
            Descargar QR
          </Button>
        )
      }
    </div>
  );
};
