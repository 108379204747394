import { getFineState } from '../utils/finesUtils';
import { Tooltip } from '@nextui-org/react';
import React, { FC, useCallback, useMemo } from 'react';

interface TrafficLightProps {
  days: number
}

export const TrafficLight: FC<TrafficLightProps> = ({
  days,
}) => {
  const getTrafficLightColor = useCallback((index: number) => {
    if (days <= 15) {
      if (index === 0) return 'bg-success';
    } else if (days > 15 && days <= 45) {
      if (index === 1) return 'bg-warning';
    } else {
      if (index === 2) return 'bg-error';
    }
  }, [days]);

  const fineState = useMemo(() => getFineState(days), [days]);

  return (
    <Tooltip
      content={
        <div className='flex flex-col justify-center w-[230px] h-16 p-3'>
          <div className='text-sm font-gothamBook'>
            {fineState.text}
            <span className={fineState.color}>{fineState.days}</span>
          </div>
        </div>
      }
      offset={-8}
      placement='bottom-end'
      color='primary'
      className='before:bg-fosted-gradient before:backdrop-blur before:frosty before:rounded-none'
      classNames={{
        content: ['bg-frosted-gradient backdrop-blur frosty border rounded-[6px] text-white'],
      }}
    >
      <div className='flex flex-row relative justify-center items-center bg-complementary-gray-main rounded-[5px] w-[100px] h-[35px] gap-[5px]'>
        {
          Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className={`${getTrafficLightColor(i) ?? 'bg-complementary-gray-dark'} w-[22px] h-[22px] rounded-full`} />
          ))
        }
      </div>
    </Tooltip>
  );
};

export default TrafficLight;
