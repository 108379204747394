import { useEffect, useState } from 'react';
import { useDebounce } from '../hooks';

interface UseSearchParams {
  search?: string
  debounce?: number
}

export const useSearch = ({ search: searchInitial, debounce }: UseSearchParams) => {
  const [search, setSearch] = useState(searchInitial ?? '');
  const debouncedSearch = useDebounce(search, debounce ?? 700);
  const [searchData, setSearchData] = useState('');

  useEffect(() => {
    setSearchData(debouncedSearch);
  }, [debouncedSearch]);

  return {
    search,
    searchDebounced: searchData,
    setSearch,
  };
};
